import React from 'react'
import About from './components/About'
import Header from '../Header'

function AboutUs() {
  return (
    <div>
    <Header />
     <About />

     
    </div>
  )
}

export default AboutUs
