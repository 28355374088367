import React from 'react'
import "../Styles/term.css"

function Term() {
  return (
    <>
        <section class="sectiondiv">
        <div class="container">
          <div class="term-container">
            <div class="main-heading">
              <h4>TERMS AND CONDITIONS</h4>
              <p>
                21st Education Private Limited is a edtech company registered
                under the provisions of Companies Act 2013 operating under the
                name “ed2100”. By accessing our website ed2100, you agree to be
                bound by the provisions of these Terms and Conditions. If you do
                not agree <br />
                to the Terms, you must not use the Site or otherwise use the
                Services.
              </p>
              <p>
                We expressly reserve the right to change these Terms and
                Conditions from time to time without notice to you. You
                acknowledge and <br />
                agree that it is your responsibility to review this site and
                these Terms of Use from time to time. Your continued use of this
                site after such modifications will constitute acknowledgement of
                the modified Terms of Use and agreement to abide and be bound by
                the modified Terms and Conditions.
              </p>
              <p>
                This Agreement explains our and your respective legal rights and
                obligations concerning all aspects of our relationship,
                including
                <br />
                without limitation account restrictions, your consent to receive
                electronic notices and dispute resolution. You hereby accept and
                agree <br />
                to the terms and conditions of this Agreement.
              </p>
            </div>
            <div class="term-title">
              <h4>USE OF THE PLATFORM/WEBSITE OF ed2100</h4>
              <p class="description">
                You agree and acknowledge that the website of ed2100 is an
                online platform that enables You to purchase product displayed
                on the website by the Supplier. The website acts as an
                intermediary between You and the Supplier. You further agree and
                acknowledge that ed2100 is only a facilitator and is not and
                cannot be a party to or control in any manner any transactions
                on the website. ed2100 is not responsible for any defect in the
                quality or deficiency of the product supplied by the Supplier.
                Any transaction or contract between You and the Suppler on the
                platform of ed2100 shall be a bipartite contract between You and
                the Supplier.
              </p>
            </div>

            <div class="term-title">
              <h4>YOUR CONDUCT WHILE USING THE PLATFORM</h4>
              <p class="description">
                You must not use the website in any way that causes, or is
                likely to cause, the website or access to it to be interrupted,
                damaged or impaired in any way. You agree that You are
                responsible for all electronic communications and content sent
                by You to us and you
                <br />
                must use the website for lawful purposes only. You agree that
                you will not upload, share, post, or otherwise distribute or
                facilitate distribution of any content of the website or use the
                website for any of the following purposes:
              </p>
              <ul>
                <li>
                  For any illegal or unlawful activity and or fraudulent
                  purposes;
                </li>
                <li>
                  To send, use or reuse any material that does not belong to
                  you; or is illegal, offensive (including but not limited to
                  material that is sexually explicit content or which promotes
                  racism, bigotry, hatred or physical harm), deceptive,
                  misleading, abusive, indecent, harassing, blasphemous,
                  defamatory, libelous, obscene, pornographic, pedophilic or
                  menacing; or is otherwise injurious to third parties;
                </li>
                <li>
                  To infringes on any patent, trademark, trade secret,
                  copyright, right of publicity, or other proprietary right of
                  any party;
                </li>
                <li>
                  To constitutes unauthorized or unsolicited advertising, junk
                  or bulk email (also known as “spamming”), chain letters, any
                  other
                  <br />
                  form of unauthorized solicitation, or any form of lottery or
                  gambling;
                </li>
                <li>
                  To disrupt or interfere with the security of, or otherwise
                  cause harm to, the Application/Website/Services/products,
                  materials,
                  <br />
                  systems resources, or gain unauthorized access to user
                  accounts, passwords, servers or networks connected to or
                  accessible <br />
                  through the Application/Website/Services/products or any
                  affiliated or linked sites;
                </li>
                <li>
                  To threaten the unity, integrity, defense, security or
                  sovereignty of India, friendly relations with foreign states,
                  or public order or causes incitement to the commission of any
                  cognizable offence or prevents investigation of any offence or
                  is insulting any other nation
                </li>
              </ul>
              <p class="description">
                We do not assume any liability for the contents of any material
                uploaded by Supplier or any third party users of the website. We
                <br />
                generally do not pre-screen, monitor, or edit the content posted
                by users of communications services, chat rooms, message boards,
                newsgroups, software libraries, or other interactive services
                that may be available on or through this website. However, we
                and our
                <br />
                agents have the right at their sole discretion to remove any
                content that, in our judgment, does not comply with these Terms
                and Conditions and any other rules of user conduct for our site,
                or is otherwise harmful, objectionable, or inaccurate. We are
                not responsible for any failure or delay in removing such
                content. You hereby consent to such removal and waive any claim
                against us arising out <br />
                of such removal of content.
              </p>
            </div>

            <div class="term-title">
              <h4>USER OBLIGATIONS</h4>
              <p class="description">
                Your use of the Platform shall be solely for your own
                educational and academic use. You will comply with all
                applicable laws in connection with your use of the Platform. You
                will not attempt to circumvent or violate any security feature
                of the Platform, including accessing any Platform features,
                interactive areas, information or profiles for which you do not
                have permission or other content or information not intended for
                you.
              </p>
            </div>

            <div class="term-title">
              <h4>OBLIGATIONS OF ed2100</h4>
              <p class="description">
                While providing the Services, the Website is acting as an
                Intermediary between the Supplier and the Users. The contract
                for the sale of goods or services will be between the Supplier
                and the User. The Supplier shall be solely responsible for all
                transactional issues relating to its supply of products
                (including information, availability, functionality, warranties,
                guarantees, order fulfilment, shipping, handling, order
                cancellation, returns, refund, adjustments, feedback and product
                or service complaints). It is the responsibility of the Supplier
                and to resolve any dispute or claim raised by the User relating
                to the purchase or sale of products or services from the
                Supplier.
              </p>
              <p class="description">
                We are obligated to perform only those duties expressly
                described in this Agreement. We shall not be liable for any
                error in judgment, for any act taken or not taken, or for any
                mistake of fact, except for as expressly provided for herein.
              </p>
            </div>

            <div class="term-title">
              <h4>CHILDREN INFORMATION</h4>
              <p class="description">
                ed2100 do not collect any personal information from children
                under the age of 18 and the Platform is available only to
                persons who can form a <br />
                legally binding contract under the Indian Contract Act, 1872. If
                you are under the age of 18 years then you must use the
                Platform, application or services under the supervision of your
                parent, legal guardian, or any responsible adult.
              </p>
            </div>

            <div class="term-title">
              <h4>PRIVACY POLICY</h4>
              <p class="description">
                We do not sell or rent Your personal information to third
                parties for their marketing purposes without Your explicit
                consent, we only use Your information for the purpose of
                facilitating you the services under this Agreement. We view
                protection of users privacy as a very important community
                principle. We understand clearly that You and Your Personal
                Information is one of our most important assets. We store and
                process Your Information in accordance with the Information
                Technology Act 2000 and rules there under. Please click here to
                know about the data protection and privacy policy of 21st
                Education Private Limited.
              </p>
            </div>

            <div class="term-title">
              <h4>Payments</h4>
              <p class="description">
                ed2100 uses third party payment providers to receive payments
                from the Users on Seller's behalf. We are not responsible for
                delays or erroneous transaction execution or cancellation of
                orders due to payment issues. We takes utmost care to work with
                third party payment providers, but does not control their
                systems, processes, technology and work flows, hence cannot be
                held responsible for any fault at the end of payment providers.
                We shall only make payments to the Supplier subject to
                realization of payment received from You to us.
              </p>
            </div>
            {/* <!-- INTELLECTUAL PROPERTY RIGHTS --> */}
            <div class="term-title">
              <h4>INTELLECTUAL PROPERTY RIGHTS</h4>
              <ol>
                <li>
                  You hereby acknowledge and agree that We are the absolute
                  owner of all right, title and interest in respect of our trade
                  name, logo, trademark, copyright, device, label, color
                  combination, artwork and visual representation and You shall
                  not, by virtue of this Agreement acquire or claim any interest
                  in our trade name or copyright belonging to and/or owned by
                  us.
                </li>
                <li>
                  All content included on the website, such as text, graphics,
                  logos, button icons, images, audio clips, digital downloads,
                  data compilations, and software, is the property of 21st
                  Education Private Limited, its affiliates or its content
                  suppliers and is protected by India and international
                  copyright, authors' rights and database right laws. The
                  compilation of all content on this website is the exclusive
                  property of 21st Education Private Limited and its affiliates
                  and is protected by laws of India and international copyright
                  and database right laws. All software used on this website is
                  the property of 21st Education Private Limited, its affiliates
                  or its software suppliers and is protected by India and
                  international copyright and author' rights laws.
                </li>
                <li>
                  You may not systematically extract/ or re-utilise parts of the
                  contents of the website without ed2100’s express written
                  consent.
                </li>
                <li>
                  You hereby agree and undertake that You shall not register,
                  use or file and/ or assist in and/ or allow registering, using
                  or filing, either directly or indirectly, for itself or
                  through, on behalf of or in conjunction with any person or
                  legal entity and whether as principal, agent, shareholder,
                  consultant, employee or in any capacity whatsoever, any
                  trademarks/ service marks in any class, either alone or in
                  combination with any other mark or material, which is similar
                  and or identical and/ or resembling in any manner with our
                  marks and/ or intellectual property rights and not to
                  associate our marks and/ or intellectual property rights with
                  your own business and/ or the business of any third party
                  associated with it, except as contemplated under this
                  Agreement.
                </li>
                <li>
                  You shall not claim any right, title, or interest in the
                  intellectual property rights of 21st Education Private Limited
                  and the same shall at all times continue to be the exclusive
                  property of the 21st Education Private Limited.
                </li>
                <li>
                  Either party shall not directly or indirectly do anything
                  which shall have an adverse impact on the other party’s marks
                  and/ or intellectual property rights and/ or confidential
                  information of the other party. In the event of termination of
                  this Agreement the provisions of this Clause shall survive the
                  termination of this Agreement.
                </li>
              </ol>
            </div>
            <div class="term-title">
              <h4>INDEMINATION AND LIMITATION OF LIABILITY</h4>
              <p class="description">
                You agree to defend, indemnify and hold harmless 21st Education
                Private Limited, its officers, directors, employees and agents,
                from and against any and all claims, damages, obligations,
                losses, liabilities, costs or debt, and expenses (including but
                not limited to attorney's fees) arising from: (i) Your use of
                and access of the Website/Services; (ii) Any request made by You
                for exchange or return of any product (ii) Your violation of any
                term of these Terms and Conditions or any other policy of the
                Company; (iii) Your violation of any third party right,
                including without limitation, any copyright, property, or
                privacy right; or (iv) any claim that Your use of the
                Website/Services has caused damage to a third party. This
                defense and indemnification obligation will survive these Terms.
                In no event shall 21st Education Private Limited, its officers,
                directors, employees, partners or agents be liable to You or any
                third party for any special, incidental, indirect, consequential
                or punitive damages whatsoever, including those resulting from
                loss of use, data or profits or any other claim arising out, of
                or in connection with, Your use of, or access to, the portal. In
                the event of Your breach of these Terms, 21st Education Private
                Limited shall be entitled to obtain an injunction against such a
                breach from any court of competent jurisdiction. The Company's
                right to obtain such relief shall not limit its right to obtain
                other remedies.
              </p>
            </div>
            {/* <!-- DISCLAIMER --> */}
            <div class="term-title">
              <h4>DISCLAIMER</h4>
              <h6>
                THIS WEBSITE, AND THE PRODUCTS ARE PROVIDED ON AN "AS IS" BASIS
                WITH ALL FAULTS AND WITHOUT ANY WARRANTY OF ANY KIND. WE HEREBY
                DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO THE
                WEBSITE, PRODUCTS AND THE SERVICES, INCLUDING WITHOUT
                LIMITATION, ALL IMPLIED WARRANTIES AND CONDITIONS OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                ACCURACY, TIMELINESS. PERFORMANCE, COMPLETENESS, SUITABILITY AND
                NON-INFRINGEMENT. ADDITIONALLY, WE SHALL NOT BE LIABLE FOR ANY
                DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE USE OR
                PERFORMANCE OF THIS WEBSITE, OR THE PRODUCTS AND SERVICES. YOUR
                USE OF ANY INFORMATION OR MATERIALS ON THIS WEBSITE IS ENTIRELY
                AT YOUR OWN RISK, FOR WHICH WE SHALL NOT BE LIABLE. YOU
                ACKNOWLEDGE AND UNDERTAKE THAT YOU ARE ACCESSING THE SERVICES ON
                THE WEBSITE AND TRANSACTING AT YOUR OWN RISK AND ARE USING YOUR
                JUDGMENT BEFORE ENTERING INTO ANY TRANSACTIONS THROUGH THE
                WEBSITE. WE SHALL NEITHER BE LIABLE NOR RESPONSIBLE FOR ANY
                ACTIONS OR INACTIONS OF SUPPLIERS NOR ANY BREACH OF CONDITIONS,
                REPRESENTATIONS OR WARRANTIES BY THE SUPPLIERS OF THE PRODUCTS
                AND HEREBY EXPRESSLY DISCLAIM AND ANY ALL RESPONSIBILITY AND
                LIABILITY IN THAT REGARD. WE SHALL NOT MEDIATE OR RESOLVE ANY
                DISPUTE OR DISAGREEMENT BETWEEN YOU AND THE SUPPLIERS OF THE
                PRODUCTS
              </h6>
            </div>
            <div class="term-title">
              <h4>RESOLUTION OF ANY VIOLATION OF RIGHTS OF USERS</h4>
              <p class="description">
                pWhenever there is a disagreement relating to any transaction,
                the users can write to _________ in order to raise a dispute.
                Once a dispute is raised by either party, ed2100 shall try to
                mediate and the resolve the matter amicably. Grievance Officer
                In accordance with the Information Technology Act, 2000 and
                rules made there under, the name and contact details of the
                Grievance Officer is published herewith:
              </p>
            </div>
            {/* <!-- ARBITRATION --> */}
            <div class="term-title">
              <h4>ARBITRATION</h4>
              <p class="description">
                If any dispute arises between You and ed2100 during Your use of
                the Website or thereafter, in connection with the validity,
                interpretation, implementation or alleged breach of any
                provision of the User Agreement, or the documents they
                incorporate by reference, the dispute shall be referred to a
                sole Arbitrator who shall be an independent and neutral third
                party mutually appointed by You and ed2100. The place of
                arbitration shall be _______, India. The arbitration proceedings
                shall be in the English Language and shall be governed by
                Arbitration & Conciliation Act, 1996
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Term