import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <>
      <header>
        <nav className="navbar navbar-expand-lg fixed-top" id="main-nav">
          <div className="container">
            <a href="/">
              <img src="img/logo.svg" width="62" alt="" className="logo" />
            </a>
            <a
              className="toggle-menu-icon"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
            >
              <span className="navbar-toggler-icon"></span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="15"
                viewBox="0 0 24 15"
                fill="none"
              >
                <path
                  d="M0.75 15V12.5H23.25V15H0.75ZM0.75 8.75V6.25H23.25V8.75H0.75ZM0.75 2.5V0H23.25V2.5H0.75Z"
                  fill="#333333"
                />
              </svg>
            </a>

            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item ">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                   {/* <li className="nav-item">
                  <a className="nav-link" href ="https://html.ed2100.com/explore">
                    Explore
                  </a>
                </li>
                   <li className="nav-item">
                  <a className="nav-link" href = "https://html.ed2100.com/genius-gauge-home">
                    Genius Gauge
                  </a>
                </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to={"/partner"}>
                    Learning Partner
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link className="nav-link" to="/about-us">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    href="https://school.ed2100.com/app/sign-in"
                    className="primary-btns nav-link"
                  >
                    Login
                  </a>
                </li>
                {/* <li className="nav-item ">
                  <a className="nav-link" href="#">
                    Genius Gauge
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Learning Partner
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <main>
        <div
          className="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">
              <img src="img/logo.svg" width="62" alt="" className="logo" />
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <div className="justify-content-end" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item ">
                  <a className="nav-link" href="/">
                    Home
                  </a>
                </li>
                <li className="nav-item ">
                <Link className="nav-link" to="/">
                    About Us
                  </Link>
                </li>
                {/* <li className="nav-item ">
                  <a className="nav-link" href="">
                    Genius Gauge
                  </a>
                </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to={"/partner"}>
                    Learning Partner
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
      
    </>
  );
}

export default Header;
