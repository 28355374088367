import React from 'react'
import { Navigation } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Fade } from 'react-reveal';
import "../../../Styles/About.css";


import linkdin from "../../../assets/linkicons.svg";
import Amit from "../../../assets/Amit-sir.png";
import Rajat from "../../../assets/Rajat.png";
import medhna from "../../../assets/MeghanaPrakash.png";
import { Link } from 'react-router-dom';


function OurTeam() {
  return (
    <>
<Fade>
      <div className="container">
        <div className="mobile-our-team-view">
          <Swiper
            // install Swiper modules
            modules={[Navigation]}
            spaceBetween={30}
            slidesPerView={5}
            navigation
            centeredSlides={true}
            breakpoints={{
              390: {
                slidesPerView: 1.2,
                spaceBetween: 20,
              },
              320: {
                slidesPerView: 1.5,
                spaceBetween: 10,
              },
              375: {
                slidesPerView: 1.5,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1.5,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1.5,
                spaceBetween: 5,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            // scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
          >
            
            <SwiperSlide>
            <div className="our-team-profile">
                <div className="our-team-content">
                  <h1>
                    Amit
                    <br /> Washington
                  </h1>
                  <div className="lindin-link">
                <Link
                  to="https://www.linkedin.com/in/amit-washington-ab51b879/"
                  target="_blank"
                >
                  <img src={linkdin} alt="icons" />
                </Link>
                </div>
                </div>
                <p>FOUNDER & CEO</p>
                <div className="profile-img-box">
                  <img src={Amit} alt="" className='amit-img' />
                  <span>
                    Amit is a global business leader with 25 years of experience
                    in multiple industries including education across India,
                    Africa and the Middle East. Amit previously served as a
                    regional CEO with the GEMS education group. He has headed
                    business in 6 countries, additionally explored business
                    opportunities in 3, servicing both B2B and B2C market
                    segments. Amit’s strength lies in business solutions,
                    go-to-market strategies, Innovation, sales & distribution
                    and operations
                  </span>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="our-team-profile">
                <div className="our-team-content">
                  <h1>
                    Rajat
                    <br /> Gupta
                  </h1>
                  <div className="lindin-link">
                <Link
                  to="https://www.linkedin.com/in/rajat-gupta-103b95a0/"
                  target="_blank"
                >
                  <img src={linkdin} alt="icons" />
                </Link>
                </div>
                </div>
                <p>Co-Founder & CPO</p>
                <div className="profile-img-box">
                  <img src={Rajat} alt="" className="rajat-img" />
                  <span>
                    Rajat drives product innovation and oversees product
                    management and technology development. With a Bachelor’s
                    degree from NIT Warangal, he excels in AR, VR, Web 3.0, and
                    Metaverse, working on architectural visualisation,
                    industrial 4.0 solutions, courier & KYC apps, and virtual
                    tours/ events. As the Director of Gaming & Reality at an
                    EdTech company, he developed a vast repository of 1000+
                    immersive 3D academic content and presented at global EdTech
                    conferences in Dubai, London, and India.
                  </span>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="our-team-profile">
                <div className="our-team-content">
                  <h1>
                    Meghana <br /> Prakash
                  </h1>
                  <div className="lindin-link">
                  <Link
                    to="https://www.linkedin.com/in/prakashmeghana/"
                    target="_blank"
                  >
                    <img src={linkdin} alt="icons" />
                  </Link>
                </div>
                </div>
                <p>Product Strategist</p>
                <div className="profile-img-box">
                  <img src={medhna} alt="" />
                  <span>
                    Meghana is a creative technologist with a passion for
                    innovation. With her experience as Head of Product and
                    Design, she has honed her expertise in AI, Robotics,
                    Metaverse, and Phygital (Physical + Digital) products. Her
                    deep understanding drives groundbreaking solutions pushing
                    boundaries and creating transformative digital experiences.
                  </span>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </Fade>
    </>
  )
}

export default OurTeam