import React from "react";
import { Navigation } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "../Styles/OurSolution.css";

// import "bootstrap/dist/css/bootstrap.min.css";
import { Fade } from "react-reveal";

import image1 from "../assets/learning1.svg";
import image2 from "../assets/learning2.svg";
import image3 from "../assets/learning3.svg";
import image4 from "../assets/learning4.svg";
import image5 from "../assets/wheelogo.jpeg";
import traitfit from "../assets/traitfit.png";
import tantalum from "../assets/tantalum_logo.jpeg";
import ipreplogo from "../assets/ipreplogo.jpeg";
import learning from "../assets/learnlanguages24Logo.jpeg";
import univeriety from "../assets/university.png";
import learnLaunge from "../assets/LearningChess_logo-svg.jpeg";
import eduvance from "../assets/eduvance.png";
import shinkan from "../assets/akashinkan-logo.svg";

function OurLearng() {
  return (
    <Fade>
      {/* <>
    <div className="row">
                 <div className="col-lg-12">
                   <div className=" learning-partner-slider">
                     <div className="item">
                       <figure>
                         <img src="img/learning-partners//fsm.png" alt="icons" />
                         <figcaption>
                           <div className="partner-name">Furtados</div>
                           <div className="country">India</div>
                         </figcaption>
                       </figure>
                     </div>
                     <div className="item">
                       <figure>
                         <img src="img/learning-partners//JoinBotics.png" alt="" />
                         <figcaption>
                           <div className="partner-name">JoinBotics</div>
                           <div className="country">India</div>
                         </figcaption>
                       </figure>
                     </div>
   
                     <div className="item">
                       <figure>
                         <img src="img/learning-partners//Kubo.png" alt="" />
                         <figcaption>
                           <div className="partner-name">Kubo</div>
                           <div className="country">India</div>
                         </figcaption>
                       </figure>
                     </div>
   
                     <div className="item">
                       <figure>
                         <img
                           src="img/learning-partners//practically.png"
                           alt=""
                         />
                         <figcaption>
                           <div className="partner-name">practically</div>
                           <div className="country">India</div>
                         </figcaption>
                       </figure>
                     </div>
   
                     <div className="item">
                       <figure>
                         <img src="img/learning-partners//Whizqiz.png" alt="" />
                         <figcaption>
                           <div className="partner-name">Trait Fit</div>
                           <div className="country">India</div>
                         </figcaption>
                       </figure>
                     </div>
                     <div className="item">
                       <figure>
                         <img src="img/learning-partners//Whizqiz.png" alt="" />
                         <figcaption>
                           <div className="partner-name">Tantalum</div>
                           <div className="country">India</div>
                         </figcaption>
                       </figure>
                     </div>
                     <div className="item">
                       <figure>
                         <img src="img/learning-partners//Whizqiz.png" alt="" />
                         <figcaption>
                           <div className="partner-name">iDream</div>
                           <div className="country">India</div>
                         </figcaption>
                       </figure>
                     </div>
                     <div className="item">
                       <figure>
                         <img src="img/learning-partners//Whizqiz.png" alt="" />
                         <figcaption>
                           <div className="partner-name">Learning chess</div>
                           <div className="country">HUNGARY</div>
                         </figcaption>
                       </figure>
                     </div>
                     <div className="item">
                       <figure>
                         <img src="img/learning-partners//Whizqiz.png" alt="" />
                         <figcaption>
                           <div className="partner-name">Shinkan</div>
                           <div className="country">India</div>
                         </figcaption>
                       </figure>
                     </div>
                     <div className="item">
                       <figure>
                         <img src="img/learning-partners//Whizqiz.png" alt="" />
                         <figcaption>
                           <div className="partner-name">Wheebox</div>
                           <div className="country">India</div>
                         </figcaption>
                       </figure>
                     </div>
                     <div className="item">
                       <figure>
                         <img src="img/learning-partners//Whizqiz.png" alt="" />
                         <figcaption>
                           <div className="partner-name">Univariety</div>
                           <div className="country">India</div>
                         </figcaption>
                       </figure>
                     </div>
                     <div className="item">
                       <figure>
                         <img src="img/learning-partners//Whizqiz.png" alt="" />
                         <figcaption>
                           <div className="partner-name">FSM</div>
                           <div className="country">India</div>
                         </figcaption>
                       </figure>
                     </div>
                     <div className="item">
                       <figure>
                         <img src="img/learning-partners//Whizqiz.png" alt="" />
                         <figcaption>
                           <div className="partner-name">Eduvance</div>
                           <div className="country">India</div>
                         </figcaption>
                       </figure>
                     </div>
                     <div className="item">
                       <figure>
                         <img src="img/learning-partners//Whizqiz.png" alt="" />
                         <figcaption>
                           <div className="partner-name">Learn Languages 24</div>
                           <div className="country">Germany</div>
                         </figcaption>
                       </figure>
                     </div>
                   </div>
                 </div>
               </div>
    </> */}
      <div className="container">
        <div className="desktop-mod">
          <Swiper
            modules={[Navigation]}
            spaceBetween={20}
            slidesPerView={5}
            navigation
            breakpoints={{
              320: {
                slidesPerView: 1.5,
                spaceBetween: 10,
              },
              390: {
                slidesPerView: 1.5,
                spaceBetween: 20,
              },
              375: {
                slidesPerView: 1.5,
                spaceBetween: 15,
              },
              640: {
                slidesPerView: 1.5,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 5,
                spaceBetween: 30,
              },
              1440: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
              1920: {
                slidesPerView: 5,
                spaceBetween: 10,
              },
            }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
          >
          
            <SwiperSlide>
              <div className="Our-Learning_partners ">
                <div className="img-box-slider">
                  <img src={image2} alt="img" />
                </div>
                <div className="card-txts">
                  <h3 className="card-title">Join Botics</h3>
                  <p>India</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Our-Learning_partners ">
                <div className="img-box-slider">
                  <img src={image3} alt="img" />
                </div>
                <div className="card-txts">
                  <h3 className="card-title">KUBO</h3>
                  <p>Denmark</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Our-Learning_partners ">
                <div className="img-box-slider">
                  <img src={image4} alt="img" />
                </div>
                <div className="card-txts">
                  <h3 className="card-title">Practically</h3>
                  <p>India</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Our-Learning_partners ">
                <div className="img-box-slider">
                  <img src={traitfit} alt="img" />
                </div>
                <div className="card-txts">
                  <h3 className="card-title">TraitFit</h3>
                  <p>India</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="Our-Learning_partners ">
                <div className="img-box-slider">
                  <img src={tantalum} alt="img" />
                </div>
                <div className="card-txts">
                  <h3 className="card-title">Tantalum</h3>
                  <p>India</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Our-Learning_partners ">
                <div className="img-box-slider">
                  <img src={ipreplogo} alt="img" />
                </div>
                <div className="card-txts">
                  <h3 className="card-title">iDream</h3>
                  <p>India</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Our-Learning_partners ">
                <div className="img-box-slider">
                  <img src={learnLaunge} alt="img" />
                </div>
                <div className="card-txts">
                  <h3 className="card-title">Learning chess</h3>
                  <p>HUNGARY</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Our-Learning_partners ">
                <div className="img-box-slider">
                  <img src={shinkan} alt="img" className="shinkan" />
                </div>
                <div className="card-txts">
                  <h3 className="card-title">Shinkan</h3>
                  <p>India</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Our-Learning_partners ">
                <div className="img-box-slider">
                  <img src={image5} alt="img" />
                </div>
                <div className="card-txts">
                  <h3 className="card-title">Wheebox</h3>
                  <p>India</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="Our-Learning_partners ">
                <div className="img-box-slider">
                  <img src={univeriety} alt="img" />
                </div>
                <div className="card-txts">
                  <h3 className="card-title">Univariety</h3>
                  <p>India</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="Our-Learning_partners ">
                <div className="img-box-slider">
                  <img src={image1} alt="img" />
                </div>
                <div className="card-txts">
                  <h3 className="card-title">FSM</h3>
                  <p>India</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="Our-Learning_partners ">
                <div className="img-box-slider">
                  <img src={eduvance} alt="img" />
                </div>
                <div className="card-txts">
                  <h3 className="card-title">Eduvance</h3>
                  <p>India</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Our-Learning_partners ">
                <div className="img-box-slider">
                  <img src={learning} alt="img" />
                </div>
                <div className="card-txts">
                  <h3 className="card-title">Learn Languages 24</h3>
                  <p>Germany</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Our-Learning_partners ">
                <div className="img-box-slider">
                  <img src={"https://games.ed2100.com/Partners-details/learnspacePic.jpg"} alt="img" />
                </div>
                <div className="card-txts">
                  <h3 className="card-title">Learn Space Science</h3>
                  <p>India</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </Fade>
  );
}

export default OurLearng;
