import React from "react";
import Ed2100Slide from "./Ed2100Slide";

function OurSolutions() {
  return (
    <section id="our-solutions">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="title-container">
              <h2 className="wow fadeIn" data-wow-delay="0.1s">
                Our <span>Solutions</span>
              </h2>
              <p className="wow fadeIn" data-wow-delay="0.5s">
                ed2100 provides one-stop-shop learning and teaching solutions to
                schools, bringing the best global programs <br />
                on a single platform at the best prices. This helps schools in
                hassle free decision making in quick time.
              </p>
            </div>
          </div>
        </div>
        <div className="hidden-xs">
          <div className="row">
            <div className="col-lg-12">
              <ul
                className="our-solutions-list wow fadeInUp"
                data-wow-delay="1s"
              >
                <li>
                  <figure>
                    <img
                      src="img/our-solutions/Global-Recognition-for-Schools.png"
                      alt=""
                    />
                    <figcaption>
                      <h3>
                        Global Recognition <br />
                        for schools
                      </h3>
                      <p>
                        Become a globally recognized school, through best
                        practices in school culture.
                      </p>
                    </figcaption>
                  </figure>
                </li>

                <li>
                  <figure>
                    <img src="img/our-solutions/Alumni-Management.png" alt="" />
                    <figcaption>
                      <h3>
                        Alumni <br />
                        Management
                      </h3>
                      <p>
                        Help you students in career guidance through the school
                        alumni.
                      </p>
                    </figcaption>
                  </figure>
                </li>

                <li>
                  <figure>
                    <img
                      src="img/our-solutions/Teachers-professional-development.png"
                      alt=""
                    />
                    <figcaption>
                      <h3>
                        Teachers professional <br />
                        development
                      </h3>
                      <p>
                        Upgrade teaching skills of your Teachers to meet the
                        needs of the 21st century classroom.
                      </p>
                    </figcaption>
                  </figure>
                </li>

                <li>
                  <figure>
                    <img
                      src="img/our-solutions/Proctoring-solution.png"
                      alt=""
                    />
                    <figcaption>
                      <h3>
                        Proctoring <br />
                        solution
                      </h3>
                      <p>
                        Making online exams authentic through our proctoring
                        services.
                      </p>
                    </figcaption>
                  </figure>
                </li>

                <li>
                  <figure>
                    <img
                      src="img/our-solutions/Hobby-centers-and-Tech-hubs.png"
                      alt=""
                    />
                    <figcaption>
                      <h3>
                        Hobby Center <br />& Tech Hubs
                      </h3>
                      <p>
                        Create Hobby Centers and Tech Hubs in your school for a
                        technology focused education.
                      </p>
                    </figcaption>
                  </figure>
                </li>

                <li>
                  <figure>
                    <img
                      src="img/our-solutions/College-admissions.png"
                      alt=""
                    />
                    <figcaption>
                      <h3>
                        College <br />
                        admissions
                      </h3>
                      <p>
                        Get complete guidance on college admissions for your
                        students.
                      </p>
                    </figcaption>
                  </figure>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile card here ... */}

      <div className="mobile-view">
        <Ed2100Slide />
      </div>
    </section>
  );
}

export default OurSolutions;
