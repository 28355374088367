import React from 'react'
import OurSolution from './OurLearng'
import OurLearng from './OurLearng'

function OurLearningPartners() {
  return (
             <section id="our-learning-partners">
             <div className="container">
               <div className="row">
                 <div className="col-lg-12">
                   <div className="title-container">
                     <h2 className="wow fadeIn" data-wow-delay="0.1s">
                       Our learning <span>partners</span>
                     </h2>
                     <p className="wow fadeIn" data-wow-delay="0.5s">
                       We curate and onboard world class domestic and international
                       EdTech partners on our platform, to <br />
                       provide the best exposure to the students, teachers and
                       their school.
                     </p>
                   </div>
                 </div>
               </div>
   
               
               <OurLearng />
             </div>
           </section>
  )
}

export default OurLearningPartners
