import React from "react";
import Accordion from "react-bootstrap/Accordion";


function NewOurProducts() {
  return (
    <>
      <div className="OurProducts-mobile-version">
       <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1">
                <Accordion.Header className="mobile-card1 ">
                  <div className="border-design">
                  <img
                        src="img/Our-features/Intelligent-learning.png"
                        alt="Intelligent learning"
                      />
                  </div>
                  <h3 className="our-title"> Intelligent learning</h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="mobile-card1-mapra">
                    AI enabled personalized education through profiling,
                    learning pathways, recommendation engine, assignments &
                    personal feedbacks.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          {/* 2 */}
          <div className="mobileViews-card">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1">
                <Accordion.Header className="mobile-card1 ">
                  <div className="border-design1">
                  <img
                        src="img/Our-features/Single-Window-Access.png"
                        alt="Intelligent learning"
                      />
                  </div>
                  <h3 className="our-title"> Single Window Access</h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="mobile-card1-mapra">
                    programs and courses from pre-k to 12th grade, academic to
                    non-academic, domestic to international.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          {/* 3 */}
          <div className="mobileViews-card">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1">
                <Accordion.Header className="mobile-card1 ">
                  <div className="border-design2">
                  <img
                        src="img/Our-features/Immersive-Learning.png"
                        alt="Intelligent learning"
                      />
                  </div>
                  <h3 className="our-title"> Immersive Learning</h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="mobile-card1-mapra">
                    learning through AR, VR, 3D simulations & gamification.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          {/* 4 */}
          <div className="mobileViews-card">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1">
                <Accordion.Header className="mobile-card1 ">
                  <div className="border-design3">
                  <img
                        src="img/Our-features/Intelligent-Teaching.png"
                        alt="Intelligent learning"
                      />
                  </div>
                  <h3 className="our-title">Intelligent teaching</h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="mobile-card1-mapra">
                    as per individual student's learning needs, course
                    recommendations, auto lesson plans, scheduling, regulated
                    lesson flows, auto Quiz, assessments & grading.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          {/* 5 */}
          <div className="mobileViews-card">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1">
                <Accordion.Header className="mobile-card1 ">
                  <div className="border-design4">
                  <img
                        src="img/Our-features/Consolidated-Growth-Mapping.png"
                        alt="Intelligent learning"
                      />
                  </div>
                  <h3 className="our-title"> Consolidated growth mapping</h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="mobile-card1-mapra">
                    For all programs on a single platform for 360° development
                    visibility.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          {/* 6 */}
          <div className="mobileViews-card">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1">
                <Accordion.Header className="mobile-card1 ">
                  <div className="border-design5">
                  <img
                        src="img/Our-features/Consolidated-Educational-Records.png"
                        alt="Intelligent learning"
                      />
                  </div>
                  <h3 className="our-title">
                   
                    Consolidated educational records
                  </h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="mobile-card1-mapra">
                    All educational records including certificate, transcripts,
                    and growth analysis will be available here.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
      </div>
    </>
  );
}

export default NewOurProducts;
