import React, { useEffect } from "react";
import Mailchimp from 'react-mailchimp-form'
import "../Components/getintouch.css"
// import HubspotContactForm from "./HubSpotContactForm";
function GetInTouch() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src='https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
        // @TS-ignore
        if (window.hbspt) {
            // @TS-ignore
            window.hbspt.forms.create({
              formId: "7a58cce3-e79a-4531-9b0d-9ccd9a32ed14",
              portalId : "43416464",
              region: "na1",
              target: '#hubspotForm',
              // cssClass:"hub-spot-form"
            })
        }
    });
}, []);
  return (
    <section id="get-in-touch">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="title-container">
              <h2 className="wow fadeIn" data-wow-delay="0.1s">
                Get in <span>touch</span>
              </h2>
              <p className="wow fadeIn" data-wow-delay="0.5s">
                Explore the world of new possibilities in education with ed2100!
              </p>
            </div>
          </div>
        </div>
        <div>
            <div id="hubspotForm" style={{display:"flex",justifyContent:"center"}}></div>
        </div>
        {/* <HubspotContactForm
          region="na1"
          portalId="12345678"
          formId='4333d218-20e3-41f9-a478-47bd0c26bf1a'
        /> */}
      </div>
    </section>
  );
}

export default GetInTouch;


// {/* <div className="row">
// <div className="col-lg-12">
//   <div className="form-container">
//     <form action="">
//       <div className="">
//         <div className="row">
//           <div className="col-lg-6">
//             <label for="exampleInputEmail1" className="form-label">
//               First Name*
//             </label>
//             <input
//               type="email"
//               className="form-control"
//               id="exampleInputEmail1"
//               aria-describedby="emailHelp"
//               placeholder="Enter First Name"
//             />
//           </div>
//           <div className="col-lg-6">
//             <label for="exampleInputEmail1" className="form-label">
//               Last Name*
//             </label>
//             <input
//               type="email"
//               className="form-control"
//               id="exampleInputEmail1"
//               aria-describedby="emailHelp"
//               placeholder="Enter Last Name"
//             />
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-lg-6">
//             <label for="exampleInputEmail1" className="form-label">
//             Contact Number                     
//              </label>
//             <input
//               type="number"
//               className="form-control"
//               id="exampleInputEmail1"
//               aria-describedby="emailHelp"
//               placeholder="Enter Contact Number"
//             />
//           </div>
//           <div className="col-lg-6">
//             <label for="exampleInputEmail1" className="form-label">
//             Name of the organization
//             </label>
//             <input
//               type="text"
//               className="form-control"
//               id="exampleInputEmail1"
//               aria-describedby="emailHelp"
//               placeholder="Enter organization name"
//             />
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-lg-6">
//             <label for="exampleInputEmail1" className="form-label">
//             Your role at the organization
//             </label>
//             <input
//               type="text"
//               className="form-control"
//               id="exampleInputEmail1"
//               aria-describedby="emailHelp"
//               placeholder="Your role at the organization?"
//             />
//           </div>
//           <div className="col-lg-6">
//             <label for="exampleInputEmail1" className="form-label">
//             Select Country
//             </label>
//             {/* <input
//               type="email"
//               className="form-control"
//               id="exampleInputEmail1"
//               aria-describedby="emailHelp"
//               placeholder="Enter Last Name"
//             /> */}
//             <select className="form-control">
//               <option>Select Your Country</option>
//               <option>India</option>
//               <option>China</option>
//             </select>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-lg-12">
//             <label for="exampleInputEmail1" className="form-label">
//            Select your City
//             </label>
//             {/* <input
//               type="email"
//               className="form-control"
//               id="exampleInputEmail1"
//               aria-describedby="emailHelp"
//               placeholder="Enter First Name"
//             /> */}
//                 <select className="form-control">
//               <option>Select Your City</option>
//               <option>Delhi</option>
//               <option>Mumbai</option>
//             </select>
//           </div>
      
//         </div>
        
//       </div>

//       <div className="">
//         <div className="row">
//           <div className="col-lg-12">
//             <label for="exampleInputEmail1" className="form-label">
//             Organization address
//             </label>
//             <textarea
//               type="text"
//               className="form-control"
//               rows="7"
//               placeholder="Ex - Minato, Roppongi Hills Mori Tower 8F, Japan"
//             ></textarea>
//           </div>
  
//         </div>
//       </div>

//       <div className="mb-3">
//         <div className="row">
//           <div className="col-lg-12">
//             <label for="exampleInputEmail1" className="form-label">
//               Type Message
//             </label>
//             <textarea
//               type="text"
//               className="form-control"
//               rows="8"
//               placeholder="Enter Here"
//             ></textarea>
//           </div>
//         </div>
//       </div>

//       <div className="mb-3">
//         <div className="row">
//           <div className="col-lg-12">
//             <input
//               type="submit"
//               className="form-control"
//               value="Send Message"
//             />
//           </div>
//         </div>
//       </div>
//     </form>
//   </div>
// </div>
// </div> */}