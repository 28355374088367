import React from 'react'

function AISection() {
  return (
             <section id="ai-education">
             <div className="container">
               <div className="row align-items-center">
                 <div className="col-lg-6 text-end">
                   <img src="img/Artificial-Intelligence.png" alt="" />
                   <div className="light__element1">
                     <img src="img/light-elements.png" alt="light" />
                   </div>
                   <div className="light__element2">
                     <img src="img/light-elements.png" alt="light" />
                   </div>
                   <div className="light__element3">
                     <img src="img/light-elements.png" alt="light" />
                   </div>
                   <div className="light__element4">
                     <img src="img/light-elements.png" alt="light" />
                   </div>
                   <div className="light__element5">
                     <img src="img/light-elements.png" alt="light" />
                   </div>
                 </div>
                 <div className="col-lg-6">
                   <div className="ai-text">
                     <h2 className="wow fadeIn" data-wow-delay="0.1s">
                       Artificial <span>Intelligence</span> (AI)
                     </h2>
                     <p className="wow fadeIn" data-wow-delay="0.5s">
                       It is the technology that develops systems that can perform
                       routine and process oriented tasks, and help humans to focus
                       on more important aspects of social, emotional and creative
                       engagements.
                     </p>
                   </div>
                 </div>
               </div>
   
               <div className="row">
                 <div className="col-lg-6">
                   <div className="ai-text">
                     <h2 className="wow fadeIn" data-wow-delay="0.1s">
                       AI in <span>Education</span>
                     </h2>
                     <ul className="wow fadeIn" data-wow-delay="0.5s">
                       <li>Each student has unique learning needs</li>
                       <li>
                         Education can only be improved by <br />
                         making it PERSONAL.
                       </li>
                       <li>
                         Education can be personalized for each <br />
                         student through AI.
                         {/* (AI does not replace
                         <br />
                         teachers but enables them) */}
                       </li>
                     </ul>
                   </div>
                 </div>
                 <div className="col-lg-6">
                   <img src="img/AI-in-Education.png" alt="" />
                 </div>
               </div>
             </div>
           </section>
  )
}

export default AISection
