import React from "react";

function ProgramOffering() {
  return (
    <section id="program-offering">
      <div className="row">
        <div className="col-lg-12">
          <div className="title-container">
            <h2 className="wow fadeIn" data-wow-delay="0.1s">
              ed2100 program <span>offerings</span>
            </h2>
            <p className="wow fadeIn" data-wow-delay="0.5s">
              We promote holistic development in students, building their
              social, emotional, physical and
              <br />
              intellectual health. This helps boost the child's confidence for
              the 21st century environment.
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="our-values-widget">
            <div className="our-values-slider-area">
              <div className="our-values-slider">
                <div className="our-values-slider-item">
                  <div className="our-values-slider-item-image gatsby-image-wrapper">
                    <img src="img/program-offerings/Scholars.jpg" alt="" />
                    <div className="caption-text">Scholars</div>
                  </div>
                </div>

                <div className="our-values-slider-item">
                  <div className="our-values-slider-item-image gatsby-image-wrapper">
                    <img src="img/program-offerings/Technologies.jpg" alt="" />
                    <div className="caption-text">Technologies</div>
                  </div>
                </div>

                <div className="our-values-slider-item">
                  <div className="our-values-slider-item-image gatsby-image-wrapper">
                    <img
                      src="img/program-offerings/Higher-Order-Thinkers.jpg"
                      alt=""
                    />
                    <div className="caption-text">Higher Order Thinkers</div>
                  </div>
                </div>

                <div className="our-values-slider-item">
                  <div className="our-values-slider-item-image gatsby-image-wrapper">
                    <img
                      src="img/program-offerings/Global-Citizens.jpg"
                      alt=""
                    />
                    <div className="caption-text">Global Citizens</div>
                  </div>
                </div>

                <div className="our-values-slider-item">
                  <div className="our-values-slider-item-image gatsby-image-wrapper">
                    <img src="img/program-offerings/Entrepreneurs.jpg" alt="" />
                    <div className="caption-text">Entrepreneurs</div>
                  </div>
                </div>

                <div className="our-values-slider-item">
                  <div className="our-values-slider-item-image gatsby-image-wrapper">
                    <img
                      src="img/program-offerings/Performing-Artists.jpg"
                      alt=""
                    />
                    <div className="caption-text">Performing Artists</div>
                  </div>
                </div>

                <div className="our-values-slider-item">
                  <div className="our-values-slider-item-image gatsby-image-wrapper">
                    <img src="img/program-offerings/Visual-Artist.jpg" alt="" />
                    <div className="caption-text">Visual Artist</div>
                  </div>
                </div>

                <div className="our-values-slider-item">
                  <div className="our-values-slider-item-image gatsby-image-wrapper">
                    <img src="img/program-offerings/Sportsmen.jpg" alt="" />
                    <div className="caption-text">Sportsmen</div>
                  </div>
                </div>

                <div className="our-values-slider-item">
                  <div className="our-values-slider-item-image gatsby-image-wrapper">
                    <img
                      src="img/program-offerings/Good-&-able-people.jpg"
                      alt=""
                    />
                    <div className="caption-text">Good & able people</div>
                  </div>
                </div>

                <div className="our-values-slider-item">
                  <div className="our-values-slider-item-image gatsby-image-wrapper">
                    <img src="img/program-offerings/Nature-Lovers.jpg" alt="" />
                    <div className="caption-text">Nature Lovers</div>
                  </div>
                </div>

                <div className="our-values-slider-item">
                  <div className="our-values-slider-item-image gatsby-image-wrapper">
                    <img
                      src="img/program-offerings/Special-education-students.jpg"
                      alt=""
                    />
                    <div className="caption-text">
                      Special education students
                    </div>
                  </div>
                </div>
                <div className="our-values-slider-item">
                  <div className="our-values-slider-item-image gatsby-image-wrapper">
                    <img
                      src="img/program-offerings/Test-Prep-seekers.jpg"
                      alt=""
                    />
                    <div className="caption-text">Test Prep seekers</div>
                  </div>
                </div>

                <div className="our-values-slider-item">
                  <div className="our-values-slider-item-image gatsby-image-wrapper">
                    <img src="img/program-offerings/International.jpg" alt="" />
                    <div className="caption-text">
                      International exams seekers
                    </div>
                  </div>
                </div>

                <div className="our-values-slider-item">
                  <div className="our-values-slider-item-image gatsby-image-wrapper">
                    <img src="img/program-offerings/Social-people.jpg" alt="" />
                    <div className="caption-text">Social people</div>
                  </div>
                </div>

                <div className="our-values-slider-item">
                  <div className="our-values-slider-item-image gatsby-image-wrapper">
                    <img
                      src="img/program-offerings/Media-personalities.jpg"
                      alt=""
                    />
                    <div className="caption-text">Media personalities</div>
                  </div>
                </div>

                <div className="our-values-slider-item">
                  <div className="our-values-slider-item-image gatsby-image-wrapper">
                    <img src="img/program-offerings/Arena.jpg" alt="" />
                    <div className="caption-text">Arena</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProgramOffering;
