import React from "react";
import poepleimg from "../../../assets/ed2100.png";
import MobileArrow from "../../../assets/Mobile-Arrow.svg";

function PeopleWe() {
  return (
    <>
    <div className="poeple-mobile-device">
      <section className="container-fluid poeple-we-sec">
        <div className="container mt-lg-5">
          <div className="people-we-care-about-sec">
            <h1 className="wow fadeIn" data-wow-delay="0.1s">
              People we
            </h1>
            <h3 className="wow fadeIn" data-wow-delay="0.2s">
              care about
            </h3>
            <div className="mobile-img">
              <img src={poepleimg} alt="" className="poeple-we-main-img" />
            </div>

            <h4 className="wow fadeIn" data-wow-delay="0.1s">
              ed2100 integrates <br /> all{" "}
              <span>
                stakeholders on
                <br /> one platform –
              </span>
            </h4>
            <ul>
              <li>
                <img src={MobileArrow} alt="MobileArrow" />
                <p>Schools</p>
              </li>
              <li>
                <img src={MobileArrow} alt="MobileArrow" />
                <p>Students</p>
              </li>
              <li>
                <img src={MobileArrow} alt="MobileArrow" />
                <p>Teachers</p>
              </li>
              <li>
                <img src={MobileArrow} alt="MobileArrow" />
                <p>Parents</p>
              </li>
              <li>
                <img src={MobileArrow} alt="MobileArrow" />
                <p>EdTech Companies</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      </div>
    </>
  );
}

export default PeopleWe;
