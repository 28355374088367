import React from "react";
import aboutimg from "../../../assets/about-hero-img.png";
import abbgsecond from "../../../assets/aout-bg-second-box.svg";
import "../../../Styles/About.css";
import bottomstar from "../../../assets/bottom-star.svg";
import storyimg from "../../../assets/child-making-robot 1.png";
import storyimgstar from "../../../assets/ede211-stroy-star.svg";
import ourimg from "../../../assets/our-vision-img1.png";
import ourmissionimg from "../../../assets/our-mission-image.png";
import poepleimg from "../../../assets/people-we-in-img.png";
import arrow from "../../../assets/arrow.svg";
import linkdin from "../../../assets/linkicons.svg";
import Amit from "../../../assets/Amit-sir.png";
import Rajat from "../../../assets/Rajat.png";
import medhna from "../../../assets/MeghanaPrakash.png";
import OurTeam from "./OurTeam";
import PeopleWe from "./PeopleWe";
import OurMission from "./OurMission";
import OurVision from "./OurVision";
import TheEdStory from "./TheEdStory";
import { Link } from "react-router-dom";

// import { Link } from "react-router-dom";

function About() {
  return (
    <>
      <section className="container-fluid hero-sec">
        <img src={abbgsecond} alt="" className="bot-box-svg" />
        <div className="hero-row">
          <div className="container hero-box1">
            <h1 className="wow fadeIn" data-wow-delay="0.1s">
              About <span>us</span>{" "}
            </h1>
            <p className="first-p wow fadeIn" data-wow-delay="0.5s">
              “A good education can build a person; the absence of which can
              waste a life.”
            </p>
            <p className="second-p wow fadeIn" data-wow-delay="0.5s">
              Amit Washington <br /> Founder & CEO ed2100
            </p>
          </div>
          <div className="hero-box2">
            <img src={bottomstar} alt="star-bg" className="star-bg-top" />
            <img src={bottomstar} alt="star-bg" className="star-bg-bottom" />
            <img src={aboutimg} alt="hero-img" className="main-img" />
          </div>
        </div>
      </section>

      {/* <!--------------------------------------------------------------
                        The ed2100 story Section
----------------------------------------------------------------> */}
      <section className="container-fluid the-story ">
        <div className="container mt-lg-5">
          <div className="row align-items-center">
            <div className="col-lg-6 stroy-img-sec">
              <img src={storyimgstar} alt="star-bg" className="star-bg-story" />
              <img
                src={storyimgstar}
                alt="star-bg"
                className="story-bg-bottom"
              />
              <img src={storyimg} alt="" className="story-main-img" />
              <h1 className="wow fadeIn" data-wow-delay="0.1s">
                The ed2100
              </h1>
              <h2 className="wow fadeIn" data-wow-delay="0.2s">
                STORY
              </h2>
            </div>
            <div className="col-lg-6">
              <div className="story-text">
                <hr className="horizontal-line" />
                <p className="wow fadeIn" data-wow-delay="0.5s">
                  At ed2100, we strongly believe that every child is unique and
                  has great potential. Each child deserves a “good education”
                  which alone can be achieved if education is personalized to
                  each student's learning needs. This becomes even more relevant
                  in the 21st century environment dominated by innovation,
                  technology, easy access to information, unprecedented change,
                  social connectivity, globalization, and growing need for
                  technical & soft skills.
                </p>
                <p className="wow fadeIn" data-wow-delay="0.6s">
                  In order for the child to succeed in this environment, apart
                  from knowledge and skills in academics, a large focus of
                  education has to be put on 21st century education and skills
                  which includes critical thinking & problem solving, creativity
                  & innovation, tech literacy, social & leadership skills, and
                  adaptability.
                </p>

                <p className="wow fadeIn" data-wow-delay="0.8s">
                  In today’s technological revolution, AI (Artificial
                  Intelligence) is personalizing experiences for us in every
                  area of our lives. It is therefore becoming exceedingly
                  imminent in the education sector, where we are preparing our
                  children for the future.
                </p>
                <hr className="The-ed2100-story-line" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="">
        <TheEdStory />
      </div>

      {/* <!--------------------------------------------------------------
                        Our Vision Section start here ...
----------------------------------------------------------------> */}
      <section className="container-fluid over-vision-sec">
        <div className="container mt-lg-5">
          <div className="our-vision-content">
            <img src={storyimgstar} alt="star-bg" className="our-vision-star" />
            <hr className="our-vision-line" />
            <img src={ourimg} alt="img" className="our-main-img" />
            <h1 className="wow fadeIn" data-wow-delay="0.1s">
              Our
            </h1>
            <h2 className="wow fadeIn" data-wow-delay="0.2s">
              VISION
            </h2>
            <p className="wow fadeIn" data-wow-delay="0.5s">
              To create an inclusive and equitable education universe of high
              quality digital <br /> learning experience for every student,
              enabling them to develop to their <br /> maximum potential and
              achieve success in the 21st century.
            </p>
          </div>
        </div>
      </section>

      <div className="">
        <OurVision />
      </div>

      {/* <!--------------------------------------------------------------
                        Our mission Section start here ...
----------------------------------------------------------------> */}

      <section className="container-fluid our-mission-sec">
        <div className="container mt-lg-5">
          <div className="row align-items-center">
            <div className="col-lg-6 stroy-img-sec">
              <img
                src={storyimgstar}
                alt="star-bg"
                className="star-bg-our-miss-sec"
              />
              <img
                src={abbgsecond}
                alt="star-bg"
                className="our-miss-bg-bottom"
              />
              <img src={ourmissionimg} alt="" className="our-miss-main-img" />
              <h1 className="wow fadeIn" data-wow-delay="0.1s">
                our
              </h1>
              <h2 className="wow fadeIn" data-wow-delay="0.2s">
                MISSION
              </h2>
            </div>
            <div className="col-lg-6">
              <hr className="horizontal-line-our-miss" />
              <div className="our-mission-sec-mapra">
                <p className="wow fadeIn" data-wow-delay="0.5s">
                  ed2100 aims to create a student-centred learning environment,
                  where students are guided every step of the way in their
                  learning journey as per their skills and interests, have
                  access to a large variety of learning resources available to
                  them in one place, are able to develop holistically and
                  contribute significantly towards nation building.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="our-mision">
        <OurMission />
      </div> 

      {/* <!--------------------------------------------------------------
                        people we care About start here ... deskto-version
----------------------------------------------------------------> */}

      <section className="container-fluid poeple-we-sec md-verison ">
        <div className="container mt-lg-5 ">
          <div className="row  ">
            <div className="col-lg-6">
              <div className="people-we-care-about-sec">
                <h3 className="wow fadeIn" data-wow-delay="0.1s">
                  ed2100 integrates <br /> all
                  <span>
                    stakeholders on
                    <br /> one platform –
                  </span>
                </h3>

                <ul>
                  <li>
                    <img src={arrow} alt="arrow" />
                    <p>Schools</p>
                  </li>
                  <li>
                    <img src={arrow} alt="arrow" />
                    <p>Students</p>
                  </li>
                  <li>
                    <img src={arrow} alt="arrow" />
                    <p>Teachers</p>
                  </li>
                  <li>
                    <img src={arrow} alt="arrow" />
                    <p>Parents</p>
                  </li>
                  <li>
                    <img src={arrow} alt="arrow" />
                    <p>EdTech Companies</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 people-we-sec">
              <img
                src={storyimgstar}
                alt="star-bg"
                className="star-bg-people-we"
              />
              <img
                src={abbgsecond}
                alt="star-bg"
                className="poeple-we-bg-bottom"
              />
              <hr className="horizontal-line-poeple-we" />

              <img src={poepleimg} alt="" className="poeple-we-main-img" />
              <h1 className="wow fadeIn" data-wow-delay="0.1s">
                People we
              </h1>
              <h2 className="wow fadeIn" data-wow-delay="0.2s">
                care about
              </h2>
            </div>
          </div>
        </div>
      </section>

      {/* Mobile version structure here .. */}
      <div className="peopel-we-care-mobile">
        <PeopleWe />
      </div>

      {/* <!--------------------------------------------------------------
                        Our Team section start here ...
----------------------------------------------------------------> */}

      <section className="container-fluid Our-Team">
        <div className="our-team-titles">
          <h1 className="wow fadeIn" data-wow-delay="0.1s">
            Our
          </h1>
          <h2 className="wow fadeIn" data-wow-delay="0.2s">
            Team
          </h2>
        </div>
        <div className="container mt-lg-5 ">
          <div className=" row row-cols-1 row-cols-md-3 gap-4">
            <div className="our-team-profile">
              <div className="our-team-content">
                <h1>
                  Amit
                  <br /> Washington
                </h1>
                <div className="lindin-link">
                <Link
                  to="https://www.linkedin.com/in/amit-washington-ab51b879/"
                  target="_blank"
                >
                  <img src={linkdin} alt="icons" />
                </Link>
                </div>
              </div>

              <p>FOUNDER & CEO</p>
              <div className="profile-img-box">
                <img src={Amit} alt="" />
                <span>
                  Amit is a global business leader with 25 years of experience
                  in multiple industries including education across India,
                  Africa and the Middle East. Amit previously served as a
                  regional CEO with the GEMS education group. He has headed
                  business in 6 countries, additionally explored business
                  opportunities in 3, servicing both B2B and B2C market
                  segments. Amit’s strength lies in business solutions,
                  go-to-market strategies, Innovation, sales & distribution and
                  operations
                </span>
              </div>
            </div>
            {/* second profile */}
            <div className="our-team-profile">
              <div className="our-team-content">
                <h1>
                  Rajat
                  <br /> Gupta
                </h1>
                <div className="lindin-link">
                <Link
                  to="https://www.linkedin.com/in/rajat-gupta-103b95a0/"
                  target="_blank"
                >
                  <img src={linkdin} alt="icons" />
                </Link>
                </div>
              </div>
              <p>Co-Founder & CPO</p>
              <div className="profile-img-box">
                <img src={Rajat} alt=""  />
                <span>
                  Rajat drives product innovation and oversees product
                  management and technology development. With a Bachelor’s
                  degree from NIT Warangal, he excels in AR, VR, Web 3.0, and
                  Metaverse, working on architectural visualisation, industrial
                  4.0 solutions, courier & KYC apps, and virtual tours/ events.
                  As the Director of Gaming & Reality at an EdTech company, he
                  developed a vast repository of 1000+ immersive 3D academic
                  content and presented at global EdTech conferences in Dubai,
                  London, and India.
                </span>
              </div>
            </div>
            {/* third profile */}
            <div className="our-team-profile">
              <div className="our-team-content">
                <h1>
                  Meghana <br /> Prakash
                </h1>
                <div className="lindin-link">
                  <Link
                    to="https://www.linkedin.com/in/prakashmeghana/"
                    target="_blank"
                  >
                    <img src={linkdin} alt="icons" />
                  </Link>
                </div>
              </div>
              <p>Product Strategist</p>
              <div className="profile-img-box">
                <img src={medhna} alt="" />
                <span>
                  Meghana is a creative technologist with a passion for
                  innovation. With her experience as Head of Product and Design,
                  she has honed her expertise in AI, Robotics, Metaverse, and
                  Phygital (Physical + Digital) products. Her deep understanding
                  drives groundbreaking solutions pushing boundaries and
                  creating transformative digital experiences.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mobile-device-desktop">
        <h4 className="wow fadeIn" data-wow-delay="0.1s">
          Our
        </h4>
        <h5 className="wow fadeIn" data-wow-delay="0.2s">
          Team
        </h5>
        <OurTeam />
      </div>
    </>
  );
}

export default About;
