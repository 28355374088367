import React from 'react'
import HeroSection from './HeroSection'
import AISection from './AISection'
import OurProduct from './OurProduct'
import ProgramOffering from './ProgramOffering'
import OurSolutions from './OurSolutions'
import OurLearningPartners from './OurLearningPartners'
import OnboardingProcess from './OnboardingProcess'
import GetInTouch from './GetInTouch'
import { Link } from 'react-router-dom'


function MainFile() {
  return (

             <>
             <main>
             <div
               className="offcanvas offcanvas-start"
               tabindex="-1"
               id="offcanvasExample"
               aria-labelledby="offcanvasExampleLabel"
             >
               <div className="offcanvas-header">
                 <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                   <img src="img/logo.svg" width="62" alt="" className="logo" />
                 </h5>
                 <button
                   type="button"
                   className="btn-close"
                   data-bs-dismiss="offcanvas"
                   aria-label="Close"
                 ></button>
               </div>
               <div className="offcanvas-body">
                 <div className="justify-content-end" id="navbarSupportedContent">
                   <ul className="navbar-nav ml-auto">
                     <li className="nav-item ">
                       <a className="nav-link" href="/">
                         Home
                       </a>
                     </li>
                     <li className="nav-item ">
                     <Link to={"/about-us"} className="nav-link">
                    About Us
                  </Link>
                     </li>
                     <li className="nav-item ">
                       <a className="nav-link" href="/">
                         Genius Gauge
                       </a>
                     </li>
                     <li className="nav-item">
                       <a
                         className="nav-link"
                         href="https://www.ed2100.com/partner/"
                       >
                         Learning Partner
                       </a>
                     </li>
                   </ul>
                 </div>
               </div>
             </div>
             {/* <!--------------------------------------------------------------
     Hero Section
     ----------------------------------------------------------------> */}
             <HeroSection />
     
             {/* <!--------------------------------------------------------------
     Artificial Intelligence Section
     ----------------------------------------------------------------> */}
     
             <AISection />
     
             {/* <!--------------------------------------------------------------
     Our Product Features Section
     ----------------------------------------------------------------> */}
     
             <OurProduct />
     
             {/* <!--------------------------------------------------------------
     Our Program offering Section
     ----------------------------------------------------------------> */}
             <ProgramOffering  />
     
             {/* <!--------------------------------------------------------------
     Our Solutions Section
     ----------------------------------------------------------------> */}
     
             <OurSolutions />
     
             {/* <!--------------------------------------------------------------
     Our Learning Partners
     ----------------------------------------------------------------> */}
             <OurLearningPartners />
     
             {/* <!--------------------------------------------------------------
     Onboarding Process
     ----------------------------------------------------------------> */}
     
             <OnboardingProcess />
     
             {/* <!--------------------------------------------------------------
     Get in touch section
     ----------------------------------------------------------------> */}
     
             <GetInTouch />


           </main>
 
             </>
    
  )
}

export default MainFile
