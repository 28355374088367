import React from "react";

function Privacy() {
  return (
    <>
      <section class="sectiondiv">
        <div class="container">
          <div class="term-container">
            <div class="main-heading">
              <h4>
                This Privacy Policy applies to the 21st Education Private
                Limited(“ed2100”)
              </h4>
              <hr />
              <p>
                21st Education Private Limited recognizes the importance of
                maintaining your privacy. This Website with the URL of _______
                (“Website/Site”) is operated under the name ed2100(“We/Our/Us”).
                We are committed to protecting and respecting your privacy. We
                collect your personal information and process your personal data
                in accordance with the Information Technology Act, 2000 and
                other laws which relate the processing of personal data. We
                collect your personal information in order to provide and
                continually improve our products and services. Our Privacy
                Policy is subject to change at any time without notice. You
                agree to review the policy from time to time to be aware of any
                changes.
              </p>
            </div>

            <div class="term-title">
              <h4>HOW WE COLLECT INFORMATION FROM YOU</h4>
              <ul>
                <li>
                  We might collect your name, email, mobile number, phone
                  number, city, state, pincode, country and IP address.
                </li>
                <li>
                  We might collect your billing name, billing address and
                  payment method, credit card details when you purchase a
                  product.
                </li>
                <li>
                  We collect information directly from you when you buy any
                  products. We also collect information if you post a comment on
                  our websites or ask <br />
                  us a question through phone or email.
                </li>
              </ul>
            </div>

            <div class="term-title">
              <h4>WHEN WE MAY USE YOUR INFORMATION</h4>
              <ul>
                <li>
                  To contact you for confirmation of a purchase on our website
                  or for other promotional purposes or for any payment purposes
                  etc.
                </li>
                <li>
                  To improve our services. This could include displaying content
                  based upon your preferences.
                </li>
                <li>
                  As and when required by law or to comply any legal or
                  statutory obligation or legal order or legal process.
                </li>
                <li>
                  We use information for security purposes to protect our
                  company, our customers, or our websites.
                </li>
                <li>
                  To send you information about special promotions or offers. We
                  might also tell you about new features or products on the
                  portals.
                </li>
                <li>
                  To fix any technical issue or to fix errors and improve the
                  usability and effectiveness of ed2100 services.
                </li>
                <li>
                  We do not sell, trade or rent your Information to any third
                  party unless, we have been expressly authorized by you either
                  in writing or <br />
                  electronically to do so. We may at times provide aggregate
                  statistics about our customers, traffic patterns, and related
                  site information to <br />
                  reputable third parties, however this information when
                  disclosed will be in an aggregate form and does not contain
                  any of your Personally Identifiable Information.
                </li>
                <li>
                  We may share your information for reasons not described in
                  this policy. We will tell you before we do this.
                </li>
              </ul>
            </div>

            <div class="term-title">
              <h4>SHARING OF PERSONAL INFORMATION</h4>
              <p class="description">
                We may share your personal information with our sellers/
                suppliers and affiliates for purposes of providing products and
                services offered by them. These entities and affiliates may
                share such information with their affiliates, business partners
                and other third parties for the purpose of providing you their
                products and services, and may market to you as a result of such
                sharing unless you explicitly opt-out. We may disclose your
                personal information to third parties, such as other sellers,
                business partners. This disclosure may be required for us to
                provide you access to our
                <br />
                products and services; for fulfilment of your orders; for
                enhancing your experience; for providing feedback on products;
                to collect payments from <br />
                you; to comply with our legal obligations; to enforce the Terms
                and Conditions. We do not disclose your personal information to
                third parties for <br />
                their marketing and advertising purposes without your explicit
                consent.
              </p>
            </div>

            <div class="term-title">
              <h4>SECURITY PRECAUTIONS</h4>
              <p class="description">
                We ensure procedural safeguards to protect your information.
                Once your information is in our possession, we adhere to our
                security guidelines to protect it against unauthorized access.
                However, by using the Platform, the users accept the inherent
                security implications of data transmission <br />
                over the internet and the World Wide Web which cannot always be
                guaranteed as completely secure, and therefore, there would
                always remain certain inherent risks regarding use of the
                Platform. Users are responsible for ensuring the protection of
                login and password records for their account.
              </p>
            </div>

            <div class="term-title">
              <h4>CHILDREN INFORMATION</h4>
              <p class="description">
                ed2100 do not collect any personal information from children
                under the age of 18 and the Platform is available only to
                persons who can form a <br />
                legally binding contract under the Indian Contract Act, 1872. If
                you are under the age of 18 years then you must use the
                Platform, application or services under the supervision of your
                parent, legal guardian, or any responsible adult.
              </p>
            </div>

            <div class="term-title">
              <h4>GRIEVANCE OFFICER</h4>
              <p class="description">
                In accordance with Information Technology Act 2000 and rules
                made there under, the name and contact details of the Grievance
                Officer are published in the Terms and Conditions
              </p>
            </div>

            <div class="term-title">
              <h4>JURISDICTION</h4>
              <p class="description">
                If you choose to visit the website, your visit and any dispute
                over privacy is subject to this Policy and the website's terms
                of use. In addition to the foregoing, any disputes arising under
                this Policy shall be governed by the laws of India.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Privacy;
