import React from "react";
import ourmissionimg from "../../../assets/newmission.png";

function OurMission() {
  return (
    <>
      <div className="Our-Mission-mobile-device">
        <section className="container-fluid">
          <div className="container mt-lg-5">
            <div className="Our-mission-title">
              <h1 className="wow fadeIn" data-wow-delay="0.1s">
                Our
              </h1>
              <h3 className="wow fadeIn" data-wow-delay="0.2s">
                MISSION
              </h3>
              <div className="our-miss-mobile-img">
                <img src={ourmissionimg} alt="" />
              </div>

              <div className="our-mission-sec-mapra">
                <p className="wow fadeIn" data-wow-delay="0.5s">
                  ed2100 aims to create a student-centred learning environment,
                  where students are guided every step of the way in their
                  learning journey as per their skills and interests, have
                  access to a large variety of learning resources available to
                  them in one place, are able to develop holistically and
                  contribute significantly towards nation building.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default OurMission;
