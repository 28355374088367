import React from 'react'


import { Navigation } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
import "../Styles/Ed2100.css"

function Ed2100Slide() {
  return (
    <>
        <div className="container ">
            <div className="mobile-Our-S-section">
              <Swiper
                // install Swiper modules
                modules={[Navigation]}
                slidesPerView={'auto'}
                spaceBetween={20}
                navigation
                // scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
                // breakpoints={{
                //   390: {
                //     slidesPerView: auto,
                //     spaceBetween: 50
                //   },
                //   640: {
                //     slidesPerView: auto,
                //     spaceBetween: 50
                //   },
                //   768: {
                //     slidesPerView: 2,
                //     spaceBetween: 40,
                //   },
                //   820: {
                //     slidesPerView: 2,
                //     spaceBetween: 40,
                //   },
                //   1024: {
                //     slidesPerView: 4,
                //     spaceBetween: 50,
                //   },
                // }}
              >
                <SwiperSlide>
                <div className="item">
                <ul className="our-solutions-list">
                  <li>
                    <figure>
                      <img
                         src="img/our-solutions/Global-Recognition-for-Schools.png"
                        alt=""
                      />
                      <figcaption>
                        <h3>
                          Global Recognition <br />
                          for schools
                        </h3>
                        <p>
                          Become a globally recognized school, through best
                          practices in school culture.
                        </p>
                      </figcaption>
                    </figure>
                  </li>
                </ul>
              </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="item">
                <ul className="our-solutions-list">
                  <li>
                    <figure>
                      <img
                        src="img/our-solutions/Alumni-Management.png"
                        alt=""
                      />
                      <figcaption>
                        <h3>
                          Alumni <br />
                          Management
                        </h3>
                        <p>
                          Help you students in career guidance through the
                          school alumni.
                        </p>
                      </figcaption>
                    </figure>
                  </li>
                </ul>
              </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="item">
                <ul className="our-solutions-list">
                  <li>
                    <figure>
                      <img
                         src="img/our-solutions/Teachers-professional-development.png"
                        alt=""
                      />
                      <figcaption>
                        <h3>
                          Teachers professional <br />
                          development
                        </h3>
                        <p>
                          Upgrade teaching skills of your Teachers to meet the
                          needs of the 21st century classNameroom.
                        </p>
                      </figcaption>
                    </figure>
                  </li>
                </ul>
              </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="item">
                <ul className="our-solutions-list">
                  <li>
                    <figure>
                    <img
                          src="img/our-solutions/Proctoring-solution.png"
                          alt=""
                        />
                      <figcaption>
                        <h3>
                          Proctoring <br />
                          solution
                        </h3>
                        <p>
                          Making online exams authentic through our proctoring
                          tool.
                        </p>
                      </figcaption>
                    </figure>
                  </li>
                </ul>
              </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="item">
                <ul className="our-solutions-list">
                  <li>
                    <figure>
                    <img src="img/our-solutions/Hobby-centers-and-Tech-hubs.png" alt="" />
                      <figcaption>
                        <h3>
                          Hobby Center <br />& Tech Hubs
                        </h3>
                        <p>
                          Create Hobby Centers and Tech Hubs in your school for
                          a holistic development of your students.
                        </p>
                      </figcaption>
                    </figure>
                  </li>
                </ul>
              </div>
                </SwiperSlide>
                {/* 2nd slider  */}
                <SwiperSlide>
                <div className="item">
                <ul className="our-solutions-list">
                  <li>
                    <figure>
                      <img
                        src="img/our-solutions/College-admissions.png"
                        alt=""
                      />
                      <figcaption>
                        <h3>
                          College <br />
                          admissions
                        </h3>
                        <p>
                          Get complete guidance on college admissions for your
                          students.
                        </p>
                      </figcaption>
                    </figure>
                  </li>
                </ul>
              </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
    </>
  )
}

export default Ed2100Slide