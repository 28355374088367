import React from "react";
import Accordion from "react-bootstrap/Accordion";
import NewOurProducts from "./NewOurProducts";

function OurProduct() {
  return (
    <>
    <section id="our-product">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="title-container">
              <h2 className="wow fadeIn" data-wow-delay="0.1s">
                ed2100 the <span>products</span>
              </h2>
              {/* <h2 className="wow fadeIn" data-wow-delay="0.1s">
                       Our<span> features</span>
                     </h2> */}
              <p className="wow fadeIn" data-wow-delay="0.5s">
                <strong>ed2100</strong> is an AI powered LXP (Learning
                Experience Platform) for schools, which offers highly
                personalized <br />
                learning and teaching environment with world class EdTech
                solutions at your fingertips.
              </p>
            </div>
          </div>
        </div>

        <div className="hidden-xs">
          <div className="row">
            <div className="col-lg-12">
              {/* <h3>Our features</h3> */}
              {/* <h3 className="title-our-features">
                       Our<span> features</span>
                     </h3> */}
              <ul className="product-features">
                <li>
                  <figure>
                    <div className="img-container">
                      <img
                        src="img/Our-features/Intelligent-learning.png"
                        alt="Intelligent learning"
                      />
                    </div>
                    <figcaption>
                      <h4>Intelligent learning</h4>
                      <p>
                        AI enabled personalized education through profiling,
                        learning pathways, recommendation engine, assignments
                        &amp; personal feedbacks.
                      </p>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <div className="img-container">
                      <img
                        src="img/Our-features/Single-Window-Access.png"
                        alt="Intelligent learning"
                      />
                    </div>
                    <figcaption>
                      <h4>
                        Single Window <br />
                        Access
                      </h4>
                      <p>
                        programs and courses from pre-k to 12th grade, academic
                        to non-academic, domestic to international.
                      </p>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <div className="img-container">
                      <img
                        src="img/Our-features/Immersive-Learning.png"
                        alt="Intelligent learning"
                      />
                    </div>
                    <figcaption>
                      <h4>
                        Immersive <br />
                        Learning
                      </h4>
                      <p>
                        learning through AR, VR, 3D simulations &amp;
                        gamification.
                      </p>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <div className="img-container">
                      <img
                        src="img/Our-features/Intelligent-Teaching.png"
                        alt="Intelligent learning"
                      />
                    </div>
                    <figcaption>
                      <h4>Intelligent teaching</h4>
                      <p>
                        As per individual student's learning needs, course
                        recommendations, auto lesson plans, scheduling,
                        regulated lesson flows, auto Quiz, assessments &amp;
                        grading.
                      </p>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <div className="img-container">
                      <img
                        src="img/Our-features/Consolidated-Growth-Mapping.png"
                        alt="Intelligent learning"
                      />
                    </div>
                    <figcaption>
                      <h4>
                        Consolidated <br />
                        growth mapping
                      </h4>
                      <p>
                        For all programs on a single platform for 360°
                        development visibility.
                      </p>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <div className="img-container">
                      <img
                        src="img/Our-features/Consolidated-Educational-Records.png"
                        alt="Intelligent learning"
                      />
                    </div>
                    <figcaption>
                      <h4>
                        Consolidated <br />
                        educational records
                      </h4>
                      <p>
                        All educational records including certificate,
                        transcripts, and growth analysis will be available here.
                      </p>
                    </figcaption>
                  </figure>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="visible-xs">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="title-our-features">
                Our<span> features</span>
              </h3>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      type="button"
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <div className="border-design">
                        <img
                          src="img/Our-features/Intelligent-learning.png"
                          alt="img"
                        />
                      </div>
                      <h3 className="our-title">Intelligent learning</h3>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="mobile-card1-mapra">
                        AI enabled personalized education through profiling,
                        learning pathways, recommendation engine, assignments
                        &amp; personal feedbacks.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <div className="border-design1">
                        <img
                          src="img/Our-features/Single-Window-Access.png"
                          alt="img"
                        />
                      </div>
                      <h3 className="our-title">Single Window Access</h3>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="mobile-card1-mapra">
                        programs and courses from pre-k to 12th grade, academic
                        to non-academic, domestic to international.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <div className="border-design2">
                        <img
                          src="img/Our-features/Immersive-Learning.png"
                          alt="img"
                        />
                      </div>
                      <h3 className="our-title">Immersive Learning</h3>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="mobile-card1-mapra">
                        learning through AR, VR, 3D simulations &amp;
                        gamification.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <div className="border-design3">
                        <img
                          src="img/Our-features/Intelligent-Teaching.png"
                          alt="img"
                        />
                      </div>
                      <h3 className="our-title">Intelligent teaching</h3>
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="mobile-card1-mapra">
                        as per individual student's learning needs, course
                        recommendations, auto lesson plans, scheduling,
                        regulated lesson flows, auto Quiz, assessments &amp;
                        grading.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <div className="border-design4">
                        <img
                          src="img/Our-features/Consolidated-Growth-Mapping.png"
                          alt="img"
                        />
                      </div>
                      <h3 className="our-title">Consolidated growth mapping</h3>
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="mobile-card1-mapra">
                        For all programs on a single platform for 360°
                        development visibility.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      <div className="border-design5">
                        <img
                          src="img/Our-features/Consolidated-Educational-Records.png"
                          alt="img"
                        />
                      </div>
                      <h3 className="our-title">
                        Consolidated educational records
                      </h3>
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p className="mobile-card1-mapra">
                        All educational records including certificate,
                        transcripts, and growth analysis will be available here.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* Mobile views card here ... */}
        {/* <div className="mobileViews-card">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1">
                <Accordion.Header className="mobile-card1 ">
                  <div className="border-design">
                  <img
                        src="img/Our-features/Intelligent-learning.png"
                        alt="Intelligent learning"
                      />
                  </div>
                  <h3 className="our-title"> Intelligent learning</h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="mobile-card1-mapra">
                    AI enabled personalized education through profiling,
                    learning pathways, recommendation engine, assignments &
                    personal feedbacks.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="mobileViews-card">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1">
                <Accordion.Header className="mobile-card1 ">
                  <div className="border-design1">
                  <img
                        src="img/Our-features/Intelligent-learning.png"
                        alt="Intelligent learning"
                      />
                  </div>
                  <h3 className="our-title"> Single Window Access</h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="mobile-card1-mapra">
                    programs and courses from pre-k to 12th grade, academic to
                    non-academic, domestic to international.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        
          <div className="mobileViews-card">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1">
                <Accordion.Header className="mobile-card1 ">
                  <div className="border-design2">
                  <img
                        src="img/Our-features/Intelligent-learning.png"
                        alt="Intelligent learning"
                      />
                  </div>
                  <h3 className="our-title"> Immersive Learning</h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="mobile-card1-mapra">
                    learning through AR, VR, 3D simulations & gamification.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          
          <div className="mobileViews-card">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1">
                <Accordion.Header className="mobile-card1 ">
                  <div className="border-design3">
                  <img
                        src="img/Our-features/Intelligent-learning.png"
                        alt="Intelligent learning"
                      />
                  </div>
                  <h3 className="our-title">Intelligent teaching</h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="mobile-card1-mapra">
                    as per individual student's learning needs, course
                    recommendations, auto lesson plans, scheduling, regulated
                    lesson flows, auto Quiz, assessments & grading.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          
          <div className="mobileViews-card">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1">
                <Accordion.Header className="mobile-card1 ">
                  <div className="border-design4">
                  <img
                        src="img/Our-features/Intelligent-learning.png"
                        alt="Intelligent learning"
                      />
                  </div>
                  <h3 className="our-title"> Consolidated growth mapping</h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="mobile-card1-mapra">
                    For all programs on a single platform for 360° development
                    visibility.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          
          <div className="mobileViews-card">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1">
                <Accordion.Header className="mobile-card1 ">
                  <div className="border-design5">
                  <img
                        src="img/Our-features/Intelligent-learning.png"
                        alt="Intelligent learning"
                      />
                  </div>
                  <h3 className="our-title">
                    {" "}
                    Consolidated educational records
                  </h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="mobile-card1-mapra">
                    All educational records including certificate, transcripts,
                    and growth analysis will be available here.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            </div>
      </div> */}
      </div>
      <NewOurProducts/>

    </section>
    
    </>
  );
}

export default OurProduct;
