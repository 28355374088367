import React from "react";

import Footer from "./Components/Footer";
// import Route from "./Routing/Route";
// import Routing from "./Routing/Route";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import AboutUs from "./Components/AboutUs";
import MainFile from "./Components/MainFile";
import Term from "./Components/Term";
import Privacy from "./Components/Privacy";
import Partner from "./Components/Partners/Partner";
import Partners from "./Components/Partners";
// import Explore from "./Components/Explore";

function App() {
  const navigate = useNavigate();
  return (
    <>
      {/* <div class="preloader__wrap">
        <div class="preloader__box">
          <div class="robot">
            <img src="img/logo.svg" alt="img" onClick={() => navigate("/")} />
          </div>
        </div>
      </div> */}
      <header>
        <nav className="navbar navbar-expand-lg fixed-top" id="main-nav">
          <div className="container">
            <a>
              <img
                src="img/logo.svg"
                width="62"
                alt=""
                className="logo"
                onClick={() => navigate("/")}
              />
            </a>
            <a
              className="toggle-menu-icon"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
            >
              <span className="navbar-toggler-icon"></span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="15"
                viewBox="0 0 24 15"
                fill="none"
              >
                <path
                  d="M0.75 15V12.5H23.25V15H0.75ZM0.75 8.75V6.25H23.25V8.75H0.75ZM0.75 2.5V0H23.25V2.5H0.75Z"
                  fill="#333333"
                />
              </svg>
            </a>

            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                  <Link to={"/"}
                    className="nav-link"
                   
                  >
                   Home{" "}
                  </Link>
                </li>
                {/* <li className="nav-item ">
                  <a className="nav-link" href="#" >
                    Home{" "}
                  </a>
                </li> */}
                {/* <li className="nav-item ">
                  <a className="nav-link" href="https://ed2100.com/genius-gauge-home">
                    Genius Gauge
                  </a>
                </li>
                <li className="nav-item ">
                  <a className="nav-link" href="https://ed2100.com/explore">
                    Explore
                  </a>
                </li> */}
                <li className="nav-item">
                  <Link to={"/partner"}
                    className="nav-link"
                   
                  >
                    Learning Partner
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/about-us"} className="nav-link">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    href="https://school.ed2100.com/app/sign-in"
                    className="primary-btns nav-link"
                  >
                    Login
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      {/* <Footer /> */}

      {/* --------- Routes ----------- */}
      <Routes>
        <Route exact path="/" element={<MainFile />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/partner" element={<Partners />} />
        <Route path="/term" element={<Term />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <Footer />
      {/* <!-- Modal --> */}
      {/* <div
        className="modal fade"
        id="get-in-touch"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
       >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Get In Touch
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form action="" className="get-in-touch">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mobile"
                  />
                </div>

                <div className="form-group">
                  <textarea
                    name="requirement"
                    id=""
                    cols="30"
                    rows="6"
                    placeholder="Write Your Requirements"
                    className="form-control"
                  ></textarea>
                </div>

                <div className="form-group">
                  <input type="submit" value="Submit" className="honey-btn" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default App;
