import React from 'react'
import Partner from './Partner'
import Navbar from './Navbar'

function Partners() {
  return (
    <div>

    <Navbar />
    <Partner />

     
    </div>
  )
}

export default Partners
