import React, { useState } from "react";
import storyimg from "../../../assets/child.png";

function TheEdStory() {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div className="The-ed-story-mobile-device">
        <section className="container-fluid">
          <div className="container mt-lg-5">
            <div className="Our-mission-title">
              <h1 className="wow fadeIn" data-wow-delay="0.1s">
                The ed2100
              </h1>
              <h3 className="wow fadeIn" data-wow-delay="0.2s">
                STORY
              </h3>
              <div className="our-miss-mobile-img">
                <img src={storyimg} alt="img" />
              </div>
              <div className="the-story-sec-mapra">
                <p className="wow fadeIn" data-wow-delay="0.5s">
                  At ed2100, we strongly believe that every child is unique and
                  has great potential.
                </p>
                

                <span
                  className={`long-text ${
                    expanded ? "expanded" : ""
                  } wow fadeIn" data-wow-delay="0.5s`}
                >
                  Each child deserves a “good education” which alone can be
                  achieved if education is personalized to each student’s
                  learning needs. This becomes even more relevant in the 21st
                  century environment dominated by innovation, technology, easy
                  access to information, unprecedented change, social
                  connectivity, globalization, and growing need for technical &
                  soft skills. In order for the child to succeed in this
                  environment, apart from knowledge and skills in academics, a
                  large focus of education has to be put on 21st century
                  education and skills which includes critical thinking &
                  problem solving, creativity & innovation, tech literacy,
                  social & leadership skills, and adaptability. In today’s
                  technological revolution, AI (Artificial Intelligence) is
                  personalizing experiences for us in every area of our lives.
                  It is therefore becoming exceedingly imminent in the education
                  sector, where we are preparing our children for the future.
                </span>
                <button
                  className="see-more"
                  onClick={() => setExpanded((!expanded))}
                >
                 {expanded ? "See Less" : "See More"}
                </button>
                
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default TheEdStory;
