import React from 'react'

function HeroSection() {
  return (
             <section className="hero-section" id="hero-section">
             <div className="container">
               <div className="row">
                 <div className="col-md-12">
                   <div className="hero-text">
                     <h1>
                       <span className="wow fadeIn" data-wow-delay="0.1s">
                         We revolutionize
                       </span>
                       <br />
                       <span className="wow fadeIn" data-wow-delay="0.5s">
                         <span>School education</span> <br />
                         <span className="wow fadeIn" data-wow-delay="1s">
                           through AI.
                         </span>
                       </span>
                     </h1>
   
                     <ul>
                       <li>AI powered LXP</li>
                       <li>Global programs</li>
                     </ul>
   
                     <a href="#get-in-touch">Book a Demo</a>
                   </div>
                   <p className="text-left">*patent in progress</p>
                 </div>
               </div>
             </div>
           </section>
  )
}

export default HeroSection
