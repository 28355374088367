import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
     <footer className='conatiner-fluid'>
        <div className="footer-container footers">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-6">
                <ul className="footNav-left">
                  <li>
                    <Link to={"/term"} target="_blanck">
                      Terms &amp; Condition
                    </Link>
                  </li>
                  <li>
                    <Link to={"/privacy"}
                      target="_blanck"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
                <ul className="footNav-left">
                  <li>
                    <a href="https://www.instagram.com/ed2100_education/?igshid=MmIzYWVlNDQ5Yg%3D%3D"   target="_blanck">
                      <img src="img/instagram.svg" alt="icon" />
                    </a>
                  </li>
                  <li className="">
                    <a href="https://www.facebook.com/ed2100education"   target="_blanck" >
                      <img src="img/facebook-square.svg" alt="icon" />
                    </a>
                  </li>
                  <li className="">
                    <a href="https://www.linkedin.com/company/ed2100education"   target="_blanck">
                      <img src="img/linkedin-square.svg" alt="icon" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 text-right">
                <ul className="footNav-right">
                  <li>
                    <p>
                      Call Us <br />
                      <a href="tel:+91-7428900818"> +91 7428900818</a>
                    </p>

                    <p>
                      Email Us <br />
                      <a href="mailto:info@ed2100.com">info@ed2100.com</a>
                    </p>

                    <p>
                    C-65 Ground Floor, DLF sector-11, Faridabad, Haryana- 121006, India.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="copyright">
                <p className="footText mb-0">
                  Copyright © 2023 21st Education Pvt. Ltd. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer