import React from "react";
import ourimg from "../../../assets/our-vision-img1.png";


function OurVision() {
  return (
    <>
      <div className="Our-vision-mobile-device">
        <section className="container-fluid">
          <div className="container mt-lg-5">
            <div className="Our-mission-title">
              <h1 className="wow fadeIn" data-wow-delay="0.1s">
                Our
              </h1>
              <h3 className="wow fadeIn" data-wow-delay="0.2s">
                VISION
              </h3>
              <div className="our-miss-mobile-img">
              <img src={ourimg} alt="img" className="our-main-img" />
              </div>
              <div className="our-vision-sec-mapra">
                <p className="wow fadeIn" data-wow-delay="0.5s">
                  To create an inclusive and equitable education universe of
                  high quality digital learning experience for every student,
                  enabling them to develop to their maximum potential and
                  achieve success in the 21st century.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default OurVision;
